import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxPayPalModule } from 'ngx-paypal';
import * as moment from 'moment-timezone';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { isObject } from 'util';

@Component({
  selector: 'ngb-timepicker-basic',
  templateUrl: './timepicker-basic.html'
})
export class NgbdTimepickerBasic {
  time = {hour: 13, minute: 30};
}

@Component({
  selector: 'app-smscoronavirus19',
  templateUrl: './smscoronavirus19.component.html',
  styleUrls: ['./smscoronavirus19.component.css']
})


export class Smscoronavirus19Component implements OnInit {
  // this.showSuccess: false;
  public totalPrice: string = "";
  public countryname: string = "";
  public days: number = 0;  
  public prefferedTime: any = {hour: '', minute: '' } ; 
  // public emailAddress: string = "";
  public extraPamsPPL : object = {}; 

  // public mobileNumber: string = '';
  public noticeBilling : string = ''; 
  // public OFF : string = 'OFF'; 
  private low : string = ".11"; 
  private mediums : string = ".14"; 
  private highs : string = "0.30";
  private uhigh : string = ".25"; 
  private ulow : string = ".020"; 

  private banshighs = [  "AC",  "AD",  "AF",  "AL",  "AM",  "AR",  "AS",  "AT",  "AZ",  "BA",  "BD",  "BE",  "BG",  "BI",  "BW",  "BY",  "CD",  "CH",  "CI",  "CM",  "CU",  "CV",  "CY",  "DE",  "DJ",  "DZ",  "EC",  "EE",  "EG",  "ES",  "ET",  "FI",  "FJ",  "FM",  "FR",  "GA",  "GE",  "GF",  "GN",  "GP",  "GU",  "GW",  "HT",  "HU",  "ID",  "IE",  "IQ",  "IT",  "JO",  "JP",  "KG",  "KH",  "KI",  "KZ",  "LK",  "LR",  "LS",  "LU",  "LV",  "LY",  "MA",  "MC",  "MG",  "MH",  "MK",  "ML",  "MM",  "MN",  "MP",  "MQ",  "MR",  "MW",  "NC",  "NE",  "NG",  "NL",  "NO",  "NP",  "NR",  "NZ",  "OM",  "PF",  "PG",  "PM",  "PS",  "PW",  "RE",  "RO",  "SB",  "SD",  "SK",  "SM",  "SN",  "SO",  "ST",  "SY",  "TD",  "TJ",  "TL",  "TM",  "TN",  "TO",  "TZ",  "UA",  "UG",  "UY",  "UZ",  "WF",  "XK",  "YE",  "ZM"];
  private bansai = ["PH"];
  private banslows = ["CA", "US" ]
  private alertermsg = "Invalid mobile phone number format provided, add the country code like +1";

  @ViewChild('emailAddress', { static: false }) emailAddress?: ElementRef;
  @ViewChild('priceElem', { static: false }) priceElem?: ElementRef;
  @ViewChild('mobileNumber', { static: false }) mobileNumber?: ElementRef;
  @ViewChild('frequencyAlert', { static: false }) frequencyAlert?: ElementRef;
  @ViewChild('spinners', { static: false }) false ?: false;
  // @ViewChild('countrycode'), {static: false}) false ? false:  
  @ViewChild('countrycode', { static: false }) countrycode?: ElementRef;
  @ViewChild('cccode', { static: false }) cccode ?: ElementRef;
  @ViewChild('ppalElem', { static: false }) ppalElem ?: ElementRef;
  
  
  public checkInbox : any = false;  
  public errorMessage : string = "";  
   


  closeResult = '';
  constructor(private modalService: NgbModal) {}
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public close(content) { }
  
  public onChangeCheckbox( isChecked: boolean) {
    this.checkInbox  = isChecked; 
    this.changePrice();
  }

  public open(content) {
    this.modalService.open(content, {size: 'lg' , scrollable: true , ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } 
  
  public payPalConfig ?: IPayPalConfig;
  // public paypalComponent = NgxPaypalComponent;

  // ngAfterViewInit() {
  generateHash(string) {
    var hash = 0;
    if (string.length == 0)
        return hash;
    for (let i = 0; i < string.length; i++) {
        var charCode = string.charCodeAt(i);
        hash = ((hash << 7) - hash) + charCode;
        hash = hash & hash;
    }
    return hash;
  }

  changeFlag(event: Event) {
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.countryname = selectElementText;

    // console.log(event);
     this.cccode = this.countrycode.nativeElement.value.toLowerCase() ;
    //  console.log(this.countrycode.nativeElement. );
  }

  changePrice(): void { 
      // console.log(  this.checkInbox  );
      // console.log(this.payPalConfig  ) ;
      // console.log(this.ppalElem );
      this.errorMessage = "";
      var output = {mobile, frequency: 0, email: '', totalprice: '', timeformatutc: '',  timeformatlocal: '', enddays: '', countrycode: '', uniqulo: '' };
      var mobile  = this.mobileNumber.nativeElement.value; 
      var countrycode  = this.countrycode.nativeElement.value; 
       
      var freq  = parseInt(this.frequencyAlert.nativeElement.value ) ; 
      // var email  = this.emailAddress.nativeElement.value; 
      var email = "sample@gmail.com"
      var timeKeeping = {hour: 0, minute: 0 } ; 

      // output.mobile = mobile;
      output.frequency = freq;
      output.email = email;
      this.days = freq; 

      var totalPrice_numbered = (freq *  parseFloat(this.mediums )).toFixed(2); 
      // this.payPalConfig = { }; 

      if (typeof this.prefferedTime == "undefined" || typeof this.prefferedTime == "string"  ) {
        this.errorMessage = "Please choose desired time to recieve SMS"; 
         
      } else if (typeof mobile == "undefined" || mobile == "" ) {
        this.errorMessage = "Please input your correct mobile"; 
        // this.payPalConfig.onError();
        // this.paypalComponent.ngOnDestroy();
        // this.payPalConfig.onError();

      } else if (typeof countrycode == "undefined" || countrycode == "" ) {
        this.errorMessage =  "Select the country data that you wish to reiceve   ";
         
      } else if (typeof freq == "undefined" || freq.toString() == "") {
        this.errorMessage =  "Please choose # of Alerts days" ;
         
      } else if (!this.checkInbox ) {
        this.errorMessage = "You must check the box to Agree with the Terms of Codition of this Site "  ;
         
      } else  {
        const phoneNumber = parsePhoneNumberFromString(mobile); 
        if (typeof phoneNumber == "undefined") {
          this.errorMessage = this.alertermsg;
          
        } else if (phoneNumber.getType() !=  "MOBILE" && phoneNumber.getType() != "FIXED_LINE_OR_MOBILE") {
          this.errorMessage = this.alertermsg;
           
        } else if (parseFloat(totalPrice_numbered ) > 0 ) {
          if (this.bansai.includes(phoneNumber.country )) {
            totalPrice_numbered = (freq *  parseFloat(this.low )).toFixed(2); 
          } else if (this.banshighs.includes(phoneNumber.country )) {
            totalPrice_numbered = (freq *  parseFloat(this.highs )).toFixed(2); 
          } else if (this.banslows.includes(phoneNumber.country )) {
            totalPrice_numbered = (freq *  parseFloat(this.ulow )).toFixed(2); 
          } else if ("YT" == phoneNumber.country) {
            totalPrice_numbered = (freq *  parseFloat(this.uhigh )).toFixed(2); 
          }
          output.mobile = phoneNumber.number;
          output.countrycode = countrycode;

          this.totalPrice = totalPrice_numbered.toString();
          timeKeeping.hour = this.prefferedTime.hour;
          timeKeeping.minute = this.prefferedTime.minute;

          var tzC = moment().format('ZZ'); 
          var labelTime =  moment().format('YYYY-MM-DD') + " " + timeKeeping.hour+':'+timeKeeping.minute + ':00 ' + tzC ;

          var dt2ime = moment(labelTime  , "YYYY-MM-DDTHH:mm:ss").utc();

          var forma = dt2ime.format(); 
          output.timeformatutc = dt2ime.format();
          
          output.enddays = moment(moment(labelTime  , "YYYY-MM-DDTHH:mm:ss").utc(), "YYYY-MM-DDTHH:mm:ss").add(output.frequency, 'days').format(); 
          output.uniqulo = moment(moment(labelTime  , "YYYY-MM-DDTHH:mm:ss").utc(), "YYYY-MM-DDTHH:mm:ss").add(output.frequency, 'days').unix(); 

          output.timeformatlocal = labelTime;  
          output.totalprice = totalPrice_numbered;
          this.noticeBilling = "<h2 class='text-success'>$"+ totalPrice_numbered +" only</h2>"; 

          this.extraPamsPPL = output; 
          this.initConfig();

          // console.log(output );

        } else {
          this.noticeBilling = ""; 
        }
      }
  }
  
  ngOnInit(): void {
    
  }

  private initConfig(): void {
    var hashed_reference = this.generateHash(JSON.stringify(this.extraPamsPPL) ) ; 
    
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'AYbkb_L9T1G0cj4lL_BY2p5okl80GNU7Y94i-Ap0ViILPsH8Z_pui6QDCjy1u59mxnwNlZ1Bza3PTFWK',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            reference_id:  JSON.stringify(this.extraPamsPPL) ,
            description: this.days + 'D/COVID19 SMS ALERT SUBSCRIPTION',
            amount: {
              currency_code: 'USD',
              value: this.totalPrice,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.totalPrice
                }
              }
            },
            items: [
              {
                name: this.days + 'D/COVID19 SMS ALERT SUBSCRIPTION',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: this.totalPrice,
                },
              }
            ]
          }
        ]
    },
    advanced: {
      commit: 'true',
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      actions.order.get().then(details => {
      });
    },
    onClientAuthorization: (data) => {
      this.noticeBilling = "<h4 class='text-success'>Your subscription payment is received, your next SMS will be sent to the given mobile.</h4>"; 

    },
    onCancel: (data, actions) => {
    },
    onError: err => {
        // console.log("errors");
        // this.ngOnDestroy(); 
    },
    onClick: (data, actions) => {
    },
  };
  }

}
