import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coronavirus-covid19',
  templateUrl: './coronavirus.component.html',
  styleUrls: ['./coronavirus.component.scss']
})
export class CoronavirusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];

  navbar.classList.remove('navbar-transparent');
}

ngOnDestroy(){
    let navbar = document.getElementsByTagName('app-navbar')[0].children[0];

}

}
