import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import * as Rellax from 'rellax';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {
    constructor( public location: Location, private element: ElementRef) {
      // this.route.params.subscribe(res => console.log(res.id));

    }

    ngOnInit() {
      
    }

    ngOnDestroy(){
     
    }

}
