import { NgxPayPalModule } from 'ngx-paypal';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ServiceWorkerModule } from '@angular/service-worker';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
// import { ExamplesModule } from './examples/examples.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';


import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
// import { NgxPayPalModule } from '../../node_modules/ngx-paypal/public_api';
import { Smscoronavirus19Component } from './pages/smscoronavirus19/smscoronavirus19.component'; 



@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        DashboardComponent,
        Smscoronavirus19Component
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        PerfectScrollbarModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        ComponentsModule 
        // ExamplesModule 
        , NgxPayPalModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
