import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { MainpageComponent } from './pages/mainpage/mainpage.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Smscoronavirus19Component } from './pages/smscoronavirus19/smscoronavirus19.component';


const routes: Routes =[
    {  path: '', redirectTo: 'mainpage', pathMatch: 'full' },
    { path: 'mainpage',             component: MainpageComponent },
    { path: 'receive_coronavirus_sms/:name',    component: Smscoronavirus19Component },
    { path: 'coronavirus_tracker',  component: DashboardComponent },
    { path: '**', component : Smscoronavirus19Component}
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
